import { getFormProps, useForm } from '@conform-to/react'
import { useFetcher, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button } from '~/components/button/button'
import { Icon } from '~/components/icon/icon'
import { useIsFetching } from '~/utils/misc'

interface FavoritedEventProps {
	eventDateId: string
	favoredByUser: boolean
	eventName?: string
	tabIndex?: boolean
}

export function FavoriteEvent({
	eventDateId,
	eventName,
	favoredByUser = false,
	tabIndex = true,
}: FavoritedEventProps) {
	const fetcher = useFetcher()
	const isPending = useIsFetching(fetcher)
	const location = useLocation()
	const { t } = useTranslation()
	// Note: useForm is a hook from @conform-to/react,
	// this had ID favorite-event earlier but was changed to dynamic (empty)
	// since it collides with the ID of all other favorite-event components
	const [form] = useForm({})

	const getColor = () => {
		if (!favoredByUser) {
			return 'text-foreground/40 dark:text-background/50'
		}
		return 'text-secondary'
	}
	const color = getColor()

	return (
		<fetcher.Form
			preventScrollReset
			method="POST"
			action="/api/event/set-favorite"
			{...getFormProps(form)}
		>
			<div>
				<input hidden readOnly value={location.pathname} name="pathname" />
				<input
					hidden
					readOnly
					value={location.search.toString()}
					name="search"
				/>
				<input hidden readOnly value={eventDateId} name="id" />
				<input
					hidden
					readOnly
					value={(!favoredByUser).toString()}
					name="favorite"
				/>
				<Button
					type="submit"
					variant="link"
					disabled={isPending}
					className={`items-center justify-center ${color}`}
					aria-label={t('action_add_to_favorite', { eventName: eventName })}
					tabIndex={!tabIndex ? -1 : undefined}
				>
					<Icon
						className="stoke-1 scale-125 stroke-white max-md:scale-150"
						name="heart-2"
						size="md"
					/>
				</Button>
			</div>
		</fetcher.Form>
	)
}
